<template>
  <v-app-bar app color="primary">
    <!-- Logo et Titre -->
    <v-app-bar-nav-icon>
      <v-icon>mdi-chat</v-icon>
    </v-app-bar-nav-icon>
    <v-toolbar-title class="ml-2">
      MistralChat
    </v-toolbar-title>

    <!-- bouton pour aller sur le chat -->
    <v-btn to="/chat" text>
      Chat
    </v-btn>

    <!-- Espace flexible -->
    <v-spacer></v-spacer>

    <!-- Bouton de déconnexion -->
    <v-btn @click="logout" text>
      Déconnexion
    </v-btn>
  </v-app-bar>
</template>

<script>

export default {
  methods: {
    logout() {
      // Ajoutez ici la logique de déconnexion (par exemple, rediriger vers la page de connexion)
      console.log("Déconnexion");
    },
    gochat(){
      console.log("gochat")
      this.$router.push('/chat')

    }
  }
};
</script>

<style scoped>
/* Ajoutez vos styles personnalisés ici */
</style>
